@import '../../../styles/basics';

.credit-card-stripe-form {
  &__inline {
    display: flex;
    gap: 8px;

    div {
      flex-basis: 50%;
    }
  }
  :global {
    .StripeElement {
      margin: 0;
      padding: 12px 34px 12px 14px;
      transition: border-color 0.3s;
      border: 1px solid $slate-1000;
      border-radius: 4px;
      background-color: $white;

      &.StripeElement--empty {
        border: 1px solid $slate-1000;
      }

      &.StripeElement--focus,
      &:hover {
        border-width: 2px;
        margin: -1px;
      }
    }

    .field-style-normal.error {
      .StripeElement {
        border-color: $red-800;
        &--focus {
          box-shadow: 0 0 0 4px $red-100;
        }
      }
    }

    .field-style-normal:not(.error) {
      .StripeElement--focus {
        outline: none;
        border-color: $turquoise-1000;
        box-shadow: 0 0 0 3px $turquoise-100;
      }
    }

    // Stripe Elements force opacity: 1 for their elements, so we do the same
    // on cardholder name field for consistency.
    .stripe-placeholder {
      &::placeholder {
        opacity: 1;
      }
    }

    .field-payment_options\.card_cvv_status,
    .field-payment_options\.card_expiry_status {
      margin: 0;
    }
  }
}
