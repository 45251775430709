@import '../../../styles/basics';

.tiktok-sociablekit {
  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 16px 24px;
    margin-bottom: 20px;
  }

  &__account-info {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 68px;
    min-height: 60px;
  }

  &__account-logo {
    position: absolute;
    top: 0;
    left: 0;

    img {
      display: block;
      width: 60px;
      height: 60px;
      border: 1px solid $gray-200;
      border-radius: 50%;
    }
  }

  &__hashtag {
    font-size: 18px;
    font-weight: $font-weight-bold;
    color: $black;
  }

  &__account-name {
    font-size: 14px;
    line-height: $line-height-normal;
    color: $slate-800;
  }

  &__account-cta {
    display: none !important;
  }

  &__account-cta--mobile {
    margin: 24px 0 0 !important;
  }

  // Original styles for the widget are quite low-quality.
  // All these odd stuff needs to make it more or less acceptable.
  :global {
    .sk-slider-container .swiper-button-prev,
    .sk-slider-container .swiper-button-next {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .sk-slider-container .swiper-button-prev {
      left: 1px !important;
    }

    .sk-ww-tiktok-hashtag-feed .sk-slider-container .swiper-button-next .sk-tiktok-feed {
      right: -4px !important;
    }

    .sk-tiktok-feed .sk-slider-container .swiper-button-next {
      right: -4px !important;
    }

    .sk-ww-tiktok-hashtag-feed {
      width: 100% !important;
      background: none !important;
      margin-bottom: 0;
    }

    .sk-tiktok-feed {
      width: 100% !important;
      background: none !important;
      margin-bottom: 0;
    }
    
    .instagram-user-root-container {
      display: none;
    }
  }

  @include media-breakpoint-up(sm) {
    &__account-cta {
      display: block !important;
      margin: 0 !important;
    }

    &__account-cta--mobile {
      display: none !important;
    }
  }

  @include media-breakpoint-up(lg) {
    &__header {
      margin-bottom: 56px;
    }
  }
}

.bb-tiktok {
  &-with-cookie {
    position: relative;
  }

  &-dialog-desktop {
    display: flex;
    padding: 27px 36px;
    border-radius: 16px;
    background-color: transparentize($biscuit-100, 0.05);

    &-header {
      margin-bottom: 19px;

      &-logo {
        display: inline-block;
        background-image: url('/static/icons/social/tiktok.svg');
        background-size: contain;
        background-repeat: no-repeat;
        height: 34px;
        width: 92px;
        margin-right: 13px;
        vertical-align: middle;
      }

      &-title {
        display: inline-block;
        margin-bottom: 8px;
        font-size: 16px;
      }
    }

    &-description {
      padding-right: 20px;

      p {
        font-size: 16px;

        @include media-breakpoint-between(md, lg) {
          line-height: $line-height-normal;
        }

        @include media-breakpoint-between(sm, md) {
          font-size: 14px;
          line-height: $line-height-normal;
        }
      }
    }

    &-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 182px;

      &-ignore-button {
        display: inline-block;
        margin-right: 15px;
        padding: 10px 20px;
        color: $turquoise-1000;
        cursor: pointer;
        font-size: 16px;

        &:hover {
          color: $turquoise-1200;
          text-decoration: underline;
        }
      }

      [data-component^="button-"] {
        margin: 0;
      }
    }
  }

  @include media-breakpoint-down(md) {
    &-dialog-desktop {
      flex-direction: column;
      text-align: center;
      padding: 50px 20px;

      &-header {
        &-title {
          display: block;
          margin: 10px auto 0;
        }
      }
    }
  }
}
