@import '../../../../../../styles/basics';

.stripe-form {
  background: $gray-100;
  margin: 0 auto;

  &__container {
    margin: 0 auto;
    padding: 32px 20px;
  }

  &__title {
    font-size: 28px;
    font-weight: $font-weight-bold;
    margin-bottom: 8px;
  }

  &__description {
    font-size: 18px;
    line-height: $line-height-large;
    font-weight: 400;
    margin-bottom: 8px;
  }

  &__submit-button {
    margin-top: 18px;
  }

  @include media-breakpoint-up(sm) {
    .stripe-form {
      &__container {
        max-width: 540px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .stripe-form {
      &__container {
        max-width: 740px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    background: none;
    max-width: 1420px;
    padding: 0 70px;
    .stripe-form {
      &__container {
        max-width: none;
        background: $gray-100;
        padding: 32px 40px 24px;
      }

      &__container-left {
        max-width: 600px;
      }

    }
  }
}
