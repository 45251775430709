@import '../../../../styles/basics';

.bb-programme {
  &:global(.bb) {
    @include bb-standalone-spacing();
    background: $turquoise-1000;
    padding-top: $bb-mobile-standalone-margin;
    padding-bottom: $bb-mobile-standalone-margin;
    @include media-breakpoint-up(md) {
      padding-top: $bb-desktop-standalone-margin;
      padding-bottom: $bb-desktop-standalone-margin;
    }
  }

  :global {
    .row [class*='col-'] {
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        color: $white;
        margin-bottom: 12px;
        @include media-breakpoint-up(md) {
          margin-bottom: 20px;
        }
      }

      .description {
        color: $white;

        p {
          color: $white;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .borders {
      margin-bottom: 0 !important;
      @include media-breakpoint-up(md) {
        display: flex;
      }

      .element {
        &.element-one {
          width: 50%;
          height: 28px;
          border-right: 2px solid $turquoise-600;
          @include media-breakpoint-up(md) {
            height: 50%;
            width: 44px;
            border-right: none;
            border-bottom: 2px solid $turquoise-600;
          }
        }

        &.element-two {
          width: 100%;
          height: 24px;
          border-top: 2px solid $turquoise-600;
          border-left: 2px solid $turquoise-600;
          border-right: 2px solid $turquoise-600;
          @include media-breakpoint-up(md) {
            height: 100%;
            width: 24px;
            border-right: none;
            border-bottom: 2px solid $turquoise-600;
          }
        }
      }
    }

    .items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 0 !important;
      @include media-breakpoint-down(md) {
        display: block;
      }

      .label {
        font-size: 18px;
        color: $white;
        padding-left: 52px;
        padding-top: 6px;
        margin: 20px 0;
        line-height: $line-height-large;
        cursor: pointer;
        position: relative;
        min-height: 40px;
        @include media-breakpoint-up(md) {
          font-size: 28px;
          padding-top: 20px;
          padding-bottom: 20px;
          margin: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          .icon {
            circle {
              fill: $white;
            }

            path {
              stroke: $turquoise-1000;
            }
          }
        }

        .icon {
          position: absolute;
          left: 0;
          top: 0;
          width: 40px;
          height: 40px;
          @include media-breakpoint-up(md) {
            top: 20px;
          }
        }
      }
    }
  }
}

.popup-programme {
  :global {
    .popup-navigation {
      .close-icon {
        @include media-breakpoint-down(md) {
          height: 36px;
          width: 36px;
          margin: 9px 20px 0 0;
        }
      }
    }
    .popup-container {
      @include media-breakpoint-down(md) {
        padding: 0;
      }
    }

    .inner {
      background: $white;
      border-top: 54px solid $turquoise-1000;
      width: 100%;
      height: 100%;
      padding: 32px 20px;
      overflow-y: auto;
      @include media-breakpoint-up(md) {
        width: 790px;
        max-width: 80%;
        padding: 40px;
        border-top: 7px solid $turquoise-1000;
        height: auto;
        max-height: 100%;
      }

      h2 {
        margin-bottom: 16px;
      }
    }
  }
}
