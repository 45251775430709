@import '../../../styles/basics';

.payment-mode-warning {
  position: absolute;
  z-index: 6600;
  top: -5px;
  right: 10px;
  a {
    opacity: 0.7;
    font-size: 11px;
    background-color: $orange-800;
    color: $white;
    text-decoration: none!important;
    padding: 2px 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: inline-block;
  }
}