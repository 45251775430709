@import '../../../styles/basics';

.webform {
  :global {
    .field-type-text,
    .field-type-select {
      max-width: 600px;
    }

    [data-component="field-error"] {
      color: $red-1000;
      font-size: 14px;
      margin-top: 6px;
      animation: append-animate-field-error .5s linear;
    }

    button {
      width: auto;
      margin-bottom: 0;

      // We can't use disabled HTML property on donation form because it
      // doesn't fire blue event to validate the form.
      // See https://github.com/facebook/react/issues/9142
      &.button-disabled,
      &.button-disabled:hover,
      &.button-disabled:focus {
        cursor: default;
        background: $pink-800;
        border-color: $pink-800;
      }
    }

    .bb-text {
      margin-top: 32px;
      margin-bottom: 32px;
      max-width: 800px;

      p {
        font-size: 16px;
      }

      .container {
        padding: 0;
      }
    }

    @media (max-width: 767px) {
      button {
        width: 100%;
      }
    }
  }
}
