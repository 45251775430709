@import '../../../../styles/basics';

.bb-giftaid {
  &__content-container {
    margin: 35px auto;
    display: flex;
    flex-direction: column-reverse;
    max-width: 561px;
    box-shadow: 0px 1px 10px rgba(106, 111, 128, 0.5);
    border-radius: 4px;

    @include media-breakpoint-up(md) {
      margin-left: 13px;
      margin-right: 13px;
      flex-direction: row;
      max-width: 936px;
    }

    @include media-breakpoint-up(lg) {
      margin: 35px auto;
    }

    @include media-breakpoint-up(xl) {
      max-width: 1116px;
    }

    :global {
      .donation-form-giftaid-container {
        height: auto;
        box-shadow: none;
        border-radius: 4px;
        padding: 30px;
        margin: 0 auto;
        text-align: center;

        @include media-breakpoint-up(md) {
          min-height: 420px;
        }

        .giftaid-heading {
          max-width: 400px;
        }

        p {
          max-width: 400px;
          margin: 14px 0 24px;
        }

        button {
          align-items: center;
          justify-content: center;
          display: inline-flex;
          height: 50px;
          letter-spacing: 0.5px;
          width: 100%;
          max-width: 400px;
          margin-left: auto;
          margin-right: auto;

          @include media-breakpoint-down(lg) {
            font-size: 16px;
          }

          @include media-breakpoint-down(sm) {
            font-size: 14px;
            padding-right: 20px;
            padding-left: 20px;
          }

          @include media-breakpoint-up(md) {
            min-width: 322px;
          }

          @include media-breakpoint-up(lg) {
            width: 386px;
          }

          .check-mark-icon {
            margin-bottom: 4px;
            margin-right: 8px;
            width: 22px;
            height: 16px;
          }

          &.submitted:focus,
          &.submitted:hover,
          &.submitted:active {
            background-color: $pink-800;
            border-color: $pink-800;
          }

          &.button-loading {
            @include media-breakpoint-up(md) {
              padding-left: 28px;
            }

            .spinner {
              top: auto;
              left: auto;
            }
          }
        }
      }

      .giftaid-image {
        @include media-breakpoint-up(lg) {
          display: flex;
          align-items: center;
        }

        img {
          width: 100%;

          @include media-breakpoint-up(md) {
            width: 330px;
          }

          @include media-breakpoint-up(lg) {
            width: 420px;
          }
        }
      }
    }
  }
}
