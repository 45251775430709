@import '../../../styles/basics';

.giftaid-question {
  text-align: center;
  padding: 30px 20px 10px;

  > img {
    min-height: 92px;
    height: 92px;
  }

  &__heading {
    font-size: 18px;
    margin: 4px auto;
  }

  p {
    font-size: 14px;
    margin-bottom: 35px;
  }

  :global {
    a[data-component^="button"] {
      font-weight: $font-weight-bold;
    }
  }

  @include media-breakpoint-up(sm) {
    padding: 30px 50px 24px;
  }

  @include media-breakpoint-up(lg) {
    max-height: 470px;
  }
}

.giftaid-question--donation-form {
  margin-bottom: 100px;
  .giftaid-question {
    &__heading {
      max-width: 315px;
    }
  }

  :global {
    a[data-component^="button"] {
      padding: 9px 30px;
    }
  }
}


.giftaid-question--inline-donation-form {
  margin: 24px;
  padding: 24px;
  max-width: 728px;
  background: $white;

  .giftaid-question {
    &__heading {
      margin-bottom: 8px;
    }
  }

  > img {
    width: 170px;
    aspect-ratio: 170/68;
  }

  p {
    margin-bottom: 24px;
  }

  :global {
    a[data-component^="button"] {
      display: block;
      width: 100%;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 48px 64px;
  }
}