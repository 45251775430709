@import '../../../../styles/basics';
@value activism-page-type from '../../../../styles/page-type.module.scss';

.bb-sm-feed {
  &:global(.bb) {
    @include bb-standalone-spacing-new();
  }

  &:global(.behaviour-settings__top-spacing--no_spacing) {
    margin-top: 0;
  }

  &:global(.behaviour-settings__bottom-spacing--no_spacing) {
    margin-bottom: 0;
  }

  // Activism styles.
  .activism-page-type & {
    &:global(.bb) {
      @include bb-activism-spacing();
    }

    &:global(.behaviour-settings__top-spacing--no_spacing) {
      margin-top: 8px;
    }

    &:global(.behaviour-settings__bottom-spacing--no_spacing) {
      margin-bottom: 8px;
    }

    &__content {
      padding: 32px 16px 40px;
      background: $white;
      border-radius: 8px;
      border: 1px solid $biscuit-100;
    }
  }

  @include media-breakpoint-up(xl) {
    .activism-page-type & {
      &__content {
        padding: 88px 80px;
      }
    }
  }
}
