@import '../../../../styles/basics';
@import '../../../../styles/mixins/default-picture';

.bb-story-carousel {
  @include bb-standalone-spacing();
  position: relative;
  overflow: hidden;

  &__slider {
    height: 548px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

  }
  &__slide {
    position: relative;

    :global {
      @include default-picture();
      .default-picture img {
        height: 548px;
      }
    }
  }
  &__gradient {
    position: absolute;
    background: linear-gradient(77.46deg, rgba(0, 0, 0, 0.7) 30.7%, rgba(0, 0, 0, 0) 100%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }

  &__overlay {
    background-color: transparent;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    padding: 30px 24px;
    display: flex;
    align-items: flex-end;
  }

  &__text {
    margin-bottom: 75px;
  }

  &__text-button {
    &:hover > svg path {
      fill: $white;
    }
  }

  &__title {
    color: $white;
    font-size: 22px;
    line-height: $line-height-normal;
  }
  &__description {
    color: $white;
    font-size: 18px;
    margin-top: 8px;
    margin-bottom: 0;
  }
  &__text-button {
    margin-top: 16px;
    margin-bottom: 0;
  }

  &__arrows {
    display: flex;
    align-items: center;
    margin-top: 16px;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 30px 24px;
  }
  &__info {
    font-size: 18px;
    font-weight: bold;
    margin: 0 16px;
    color: $white;
  }
  &__arrow {
    width: 44px;
    cursor: pointer;

    g {
      fill: $blue-800;
      stroke: $blue-800;
      &:hover {
        fill: $blue-1000;
        stroke: $blue-1000;
      }
    }
    path {
      stroke: $white;
    }

    &--prev {
      transform: rotate(180deg);
    }
    &--inactive {
      cursor: default;
      opacity: 0.25;
    }
  }

  &__next-button {
    display: none;
    margin-bottom: 0;
    &--inactive {
      opacity: 0.25;
      cursor: default;
    }
  }

  @include media-breakpoint-up(md) {
    &__slider {
      height: 600px;
    }
    &__slide {
      :global(.default-picture) img {
        height: 600px;
      }
    }
    &__gradient {
      background: linear-gradient(27.18deg, rgba(0, 0, 0, 0.8) 43.3%, rgba(0, 0, 0, 0) 100%);
    }
    &__arrows,
    &__overlay {
      padding: 40px;
    }
    &__text {
      margin-bottom: 85px;
      max-width: 400px;
    }
    &__title {
      font-size: 36px;
    }
    &__description {
      margin-top: 16px;
    }
    &__button-text {
      margin-top: 24px;
    }
    &__arrow--next {
      display: none;
    }
    &__next-button {
      display: inline-block;
    }
  }

}
