@import '../../../../styles/basics';
@import '../../../../styles/mixins/default-picture';

.bb-activism-info-with-icons {
  &:global(.bb) {
    @include bb-activism-spacing();
  }

  &:global(.behaviour-settings__top-spacing--no_spacing) {
    margin-top: 8px;
  }

  &:global(.behaviour-settings__bottom-spacing--no_spacing) {
    margin-bottom: 8px;
  }

  :global {
    @include default-picture();
  }

  &__content {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__image img {
    border-radius: 8px;
  }

  &__info {
    background: $pink-800;
    padding: 0 8px 7px;
    border-radius: 8px;
  }

  .info {
    &__header {
      gap: 24px;
      display: flex;
      align-items: center;
      padding: 16px 8px 16px 16px;
    }
    &__header_icon {
      height: 64px;
      width: 80px;
    }
    &__header_title {
      text-align: center;
      color: $yellow-600;
      font-size: 28px;
      font-weight: bold;
    }
    &__items {
      background: $white;
      border-radius: 8px;
      padding: 24px 8px 40px 20px;
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    &__item_icon {
      height: 76px;
      width: 76px;
    }

    &__item_icon_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__item_number {
      font-size: 32px;
      font-weight: bold;
      line-height: $line-height-normal;
      color: $pink-800;
    }
  }

  @include media-breakpoint-up(md) {
    &__content {
      flex-direction: row;
    }
    &__info {
      width: 50%;
    }
    &__image:global(.default-picture) {
      width: 50%;
      img {
        height: 100%;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &__content {
      position: relative;
    }
    &__info {
      position: absolute;
      max-width: 440px;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      margin: 32px;
      padding: 0 32px 32px;
    }
    &__image:global(.default-picture) {
      width: 100%;
      height: 617px;
    }
    .info {
      &__header {
        padding: 24px 0 24px 32px;
      }
      &__header_icon {
        height: 80px;
        width: 100px;
      }
      &__header_title {
        font-size: 36px;
      }

      &__items {
        padding: 32px 40px;
      }

      &__item_number {
        font-size: 60px;
        line-height: 1;
      }
      &__item_text {
        font-size: 18px;
      }
      &__item_icon {
        width: 88px;
        height: 88px;
      }
    }
  }
}
