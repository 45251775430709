@import '../../../../styles/basics';

.bb-activism-resources {
  @include bb-activism-spacing();
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;

  &__items-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
