@import '../../../../../../styles/basics';

.paypal-form {
  background: $gray-100;
  margin: 0 auto;

  &__container {
    margin: 0 auto;
    padding: 32px 20px 40px;
  }

  &__title {
    font-size: 28px;
    font-weight: $font-weight-bold;
    margin-bottom: 8px;
  }

  &__description {
    font-size: 18px;
    line-height: $line-height-large;
    font-weight: 400;
    margin-bottom: 8px;
  }

  &__gift-aid {
    background-color: $biscuit-100;
    border: 1px solid $slate-600;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;

    &-title {
      font-size: 16px;
      font-weight: $font-weight-bold;
      margin-bottom: 16px;
    }

    img {
      width: 150px;
      margin-bottom: 16px;
    }

    :global {
      .field-gift_aid {
        margin-bottom: 8px;
        label {
          padding: 16px;
          .label {
            margin-left: 40px;
          }
          .indicator {
            left: 16px;
            top: 20px;
          }
        }
      }
    }
    &-info {
      display: flex;
      justify-content: flex-end;
    }
  }

  :global {
    .paypal-button-wrapper {
      margin-top: 20px;
      width: 100%;
    }
  }

  @include media-breakpoint-up(sm) {
    .paypal-form {
      &__container {
        max-width: 540px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .paypal-form {
      &__container {
        max-width: 740px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    background: none;
    max-width: 1420px;
    padding: 0 70px;
    .paypal-form {
      &__container {
        max-width: none;
        background: $gray-100;
        padding: 32px 40px 40px;
      }

      &__container-left {
        max-width: 600px;
      }

    }
  }
}
