@import '../../../../../../styles/basics';

.giftaid {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.4);
  z-index: 10000; // same as popup.
  display: flex;
  justify-content: center;
  align-items: center;
}