@import '../../../styles/basics';
@value activism-page-type, activism-page-type--variant-project-us from '../../../styles/page-type.module.scss';

.step {
  background: $white;
  border: 1px solid rgba(#000, .05);
  border-radius: 9px;
  color: $gray-1200;

  &__content {
    display: flex;
    flex-direction: column;
    max-width: 1056px;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(lg) {
      flex-direction: row;

      .step--odd & {
        flex-direction: row-reverse;
      }
    }
  }

  &__image-group {
    padding: 48px 40px 0;
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(lg) {
      width: 50%;
      padding: 56px;
    }

    @include media-breakpoint-up(xl) {
      padding-left: 72px;
      padding-right: 72px;
    }
  }

  &__info-group {
    padding: 36px 24px 48px;
    position: relative;
    z-index: 1;

    @include media-breakpoint-up(lg) {
      width: 50%;
      padding: 64px 24px;
    }
  }

  &__image {
    aspect-ratio: 314/290;
    position: relative;
    max-width: 238px;

    // Smooth rotating in Chrome.
    -webkit-backface-visibility: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }

    .step--type-1 & {
      transform: rotate(9.36deg);

      @include media-breakpoint-up(xl) {
        right: 20px;
      }
    }

    .step--type-2 & {
      transform: rotate(-8.7deg);

      @include media-breakpoint-up(xl) {
        left: -34px;
      }
    }

    .step--type-3 & {
      transform: rotate(-6.91deg);

      @include media-breakpoint-up(xl) {
        right: -2px;
      }
    }

    .step--type-4 & {
      transform: rotate(-1.77deg);

      @include media-breakpoint-up(xl) {
        left: 10px;
      }
    }

    @include media-breakpoint-up(lg) {
      max-width: 314px;

      .step--odd & {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }

  &__bullet {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    position: relative;
    z-index: 1;

    @include media-breakpoint-up(lg) {
      width: 80px;
      height: 80px;
    }

    &--icon img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      display: block;
    }

    &--number {
      font-size: 32px;
      font-weight: $font-weight-bold;
      color: $white;

      @include media-breakpoint-up(lg) {
        font-size: 36px;
      }
    }
  }

  // Default color is the same as for
  // .activism-page-type--variant-one-planet.
  &__number-bg {
    width: 100%;
    height: auto;
    position: absolute;
    fill: $blue-600;
    z-index: -1;
  }

  &__title {
    font-size: 28px;
    margin-top: 16px;
    color: $gray-1200;

    @include media-breakpoint-up(lg) {
      font-size: 36px;
      margin-top: 24px;
    }
  }

  &__description {
    font-size: 18px;
    margin-top: 8px;

    @include media-breakpoint-up(lg) {
      font-size: 22px;
      margin-top: 16px;
    }
  }

  &__action {
    margin-top: 24px;

    :global(.text-button) {
      margin: 0;
    }
  }

  .activism-page-type--variant-project-us & {
    &__number-bg {
      fill: $pink-600;
    }
  }
}
