@import '../../../../styles/basics';

.bb-activism-quote {
  &:global(.bb) {
    @include bb-activism-spacing();
  }

  &:global(.behaviour-settings__top-spacing--no_spacing) {
    margin-top: 8px;
  }

  &:global(.behaviour-settings__bottom-spacing--no_spacing) {
    margin-bottom: 8px;
  }

  &__content-wrapper {
    background: $white;
    border: 1px solid $biscuit-100;
    border-radius: 8px;
    padding: 16px;
    color: $gray-1200;

    p {
      color: $gray-1200;
    }
  }

  &__title {
    margin-top: 16px;
    margin-bottom: 24px;
    color: $gray-1200;
    font-size: 28px;
    line-height: $line-height-normal;
  }

  &__quote-group {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: $biscuit-100;
    border-radius: 8px;
    overflow: hidden;
  }

  &__image {
    position: relative;
    aspect-ratio: 304/396;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__quote-wrapper {
    padding: 82px 16px 40px;
  }

  &__quote-content {
    width: 100%;
    position: relative;
  }

  &__mark {
    position: absolute;
    left: 0;
    bottom: calc(100% + 20px);

    path {
      fill: $blue-800;
    }
  }

  &__quote {
    p {
      font-size: 18px;
      line-height: $line-height-normal;
      margin-bottom: 0;
    }
  }

  &__name-group {
    margin-top: 16px;
    font-size: 13px;
    line-height: $line-height-normal;
  }

  &__additional-content {
    margin-top: 32px;
    // Preventing collapsing margins.
    overflow: hidden;
  }

  &__sm-feed {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  &__text-hl,
  &__text {
    p {
      margin-bottom: 24px;
    }
  }

  &__text-hl {
    p {
      font-size: 18px;
      line-height: $line-height-normal;
    }
  }

  &__text {
    p {
      line-height: $line-height-large;
    }
  }

  @include media-breakpoint-up(sm) {
    &__image {
      aspect-ratio: 466/396;
    }
  }

  @include media-breakpoint-up(md) {
    &__title {
      margin-bottom: 32px;
    }

    &__image {
      aspect-ratio: 664/396;
    }
  }

  @include media-breakpoint-up(lg) {
    &__image {
      aspect-ratio: 820/396;
    }
  }

  @include media-breakpoint-up(xl) {
    &__content-wrapper {
      padding: 64px 80px;
    }

    &__title {
      margin-top: 0;
      margin-bottom: 40px;
      font-size: 36px;
    }

    &__quote-group {
      flex-direction: row;
    }

    &__image {
      aspect-ratio: auto;
      width: 374px;
      min-height: 424px;
    }

    &__quote-wrapper {
      width: calc(100% - 374px);
      padding: 48px 32px 48px 88px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__quote-content {
      max-width: 560px;
    }

    &__mark {
      position: absolute;
      left: auto;
      bottom: auto;
      top: 0;
      right: calc(100% + 20px);
    }

    &__quote {
      p {
        font-size: 28px;
        line-height: $line-height-large;
      }
    }

    &__name-group {
      margin-top: 24px;
    }

    &__additional-content {
      margin-top: 56px;
      max-width: 760px;
      margin-left: auto;
      margin-right: auto;
    }

    &__text-hl {
      p {
        font-size: 28px;
        line-height: $line-height-large;
      }
    }

    &__text {
      p {
        font-size: 18px;
        margin-bottom: 28px;
      }
    }

    &__sm-feed {
      margin-top: 56px;
    }
  }

}
