@import '../../../../styles/basics';
@value loading-text from '../../../01_atoms/LoadingText/index.module.scss';

.bb-inline-donation-form {
  &__loading {
    .loading-text {
      font-weight: $font-weight-bold;
      font-size: 42px;
      color: $white;

      @include media-breakpoint-up(sm) {
        font-size: 64px;
      }

      @include media-breakpoint-up(md) {
        font-size: 72px;
        color: $white;
      }
    }

    :global {
      .popup-navigation {
        display: none;
      }
    }
  }
}
