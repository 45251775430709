@import '../../../styles/basics';

.total-cost {
  color: $black;
  display: flex;
  justify-content: space-between;
  font-size: 36px;
  font-weight: 700;
  line-height: $line-height-normal;
  margin-top: 32px;
}
