@import '../../../styles/basics';
@value activism-page-type from '../../../styles/page-type.module.scss';

.activism-block-info {
  margin-bottom: 24px;

  &__title {
    font-size: 32px;
  }

  &__subtitle {
    font-size: $font-size-lg;
    max-width: 1280px;
  }
}

.activism-page-type .activism-block-info {
  &__title {
    color: $gray-1200;
    margin-bottom: 8px;
    &--normal {
      font-size: 28px;
    }
    &--large {
      font-size: 32px;
    }
  }

  &__subtitle {
    font-size: 18px;
    color: $gray-1200;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 32px;
    &__title {
      &--normal {
        font-size: 32px;
      }
      &--large {
        font-size: 60px;
      }
    }
    &__subtitle {
      font-size: 22px;
    }
  }
}
