@import '../../../styles/basics';

.step {
  :global {
    .wrapper {
      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: stretch;
      }
    }

    .col-first,
    .col-last {
      @include media-breakpoint-up(lg) {
        width: 50%;
        flex: 0 0 auto;
      }
    }

    .col-first {
      @include media-breakpoint-up(lg) {
        position: relative;
      }
    }

    .content {
      padding: 24px;
      text-align: center;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
      @include media-breakpoint-up(md) {
        padding: 48px;
      }
      @include media-breakpoint-up(lg) {
        text-align: left;
        max-width: 468px;
        margin-left: 0;
        padding-right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 326px;
      }
      @include media-breakpoint-up(xl) {
        max-width: 558px;
      }
      @media all and (min-width: 1520px) {
        min-height: 438px;
      }
    }

    .image {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-position: center center;
        background-size: cover;
      }
    }

    .number {
      display: inline-flex;
      width: 80px;
      height: 80px;
      justify-content: center;
      align-items: center;
      padding: 16px;
      line-height: 48px;
      font-size: 43px;
      font-weight: $font-weight-bold;
      border-radius: 50%;
      background: $white;
    }

    .title,
    .description {
      color: $white;
    }

    .title {
      margin-top: 10px;
      font-size: 22px;
      @include media-breakpoint-up(md) {
        font-size: 28px;
        margin-top: 12px;
      }
    }

    .description {
      margin-top: 10px;
      font-size: 20px;
      @include media-breakpoint-up(md) {
        margin-top: 6px;
      }
    }
  }

  &:global(.step--even) {
    :global {
      .wrapper {
        @include media-breakpoint-up(lg) {
          flex-direction: row-reverse;
        }
      }
      .content {
        @include media-breakpoint-up(lg) {
          margin-left: auto;
          padding-right: 48px;
          margin-right: 0;
          padding-left: 0;
        }
      }
    }
  }

  &:global(.step--bg-teal) {
    background: $turquoise-600;

    :global(.number) {
      color: $turquoise-600;
    }
  }

  &:global(.step--bg-deep-turquoise) {
    background: $turquoise-1200;

    :global(.number) {
      color: $turquoise-1200;
    }
  }

  &:global(.step--bg-green) {
    background: $green-800;

    :global(.number) {
      color: $green-800;
    }
  }

  &:global(.step--bg-turquoise) {
    background: $turquoise-1000;

    .number {
      color: $turquoise-1000;
    }
  }
}
