@import '../../../../styles/basics';

.bb-activism-four-tile {
  &:global(.bb) {
    @include bb-activism-spacing();
  }

  &:global(.behaviour-settings__top-spacing--no_spacing) {
    margin-top: 12px;
  }

  &:global(.behaviour-settings__bottom-spacing--no_spacing) {
    margin-bottom: 12px;
  }

  &__label {
    display: inline-block;
    padding: 8px 16px;
    background: $blue-600;
    border-radius: 8px;
    margin-bottom: 12px;
    font-weight: $font-weight-bold;
    color: $gray-1200;
    text-transform: uppercase;
  }

  &__items-list {
    display: flex;
    flex-direction: column;
    gap: 0 12px;
  }

  &__item {
    display: flex;
    align-items: stretch;
    min-height: 286px;
    overflow: hidden;

    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:nth-child(odd) {
      flex-direction: row-reverse;
    }
  }

  &__item-image,
  &__item-info {
    width: 50%;
    flex: 0 0 auto;
  }

  &__item-image {
    position: relative;
    
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__item-info {
    background: $black;
    color: $white;
    padding: 32px 24px 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
  }

  &__item-title-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__item-number {
    flex: 0 0 auto;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    font-size: 22px;
    font-weight: $font-weight-bold;
    color: $black;
  }

  &__item-number-bg {
    width: 100%;
    height: auto;
    position: absolute;
    fill: $blue-600;
    z-index: -1;
  }

  &__item-title {
    font-size: 18px;
    line-height: $line-height-normal;
    font-weight: $font-weight-bold;
    color: $blue-600;
  }

  &--variant-yellow {
    .bb-activism-four-tile__label {
      background: $yellow-600;
    }

    .bb-activism-four-tile__item-number-bg {
      fill: $yellow-600;
    }

    .bb-activism-four-tile__item-title {
      color: $yellow-600;
    }
  }

  @include media-breakpoint-up(xl) {
    &__items-list {
      flex-direction: row;
      align-items: stretch;
    }

    &__item {
      // Equal column width;
      flex: 1 1 0;
      border-radius: 8px;
      min-height: auto;

      &, &:nth-child(odd) {
        flex-direction: column;
      }
    }

    &__item-image,
    &__item-info {
      width: 100%;
    }

    &__item-image {
      height: 200px;
    }

    &__item-info {
      justify-content: flex-start;
      flex-grow: 1;
      padding: 24px 24px 40px;
    }

    &__item-title {
      font-size: 22px;
    }

    &__item-description {
      font-size: 18px;
    }
  }

  @media (min-width: 1400px) {
    &__item-title-group {
      flex-direction: row;
    }
  }
}
