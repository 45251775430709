@import '../../../styles/basics';

.fundraising-costs {
  margin-top: 32px;
  background: $turquoise-100;
  border: 1px solid $turquoise-100;
  padding: 15px 15px 15px 15px + 40px;
  border-radius: 4px;

  :global(.field-type-checkboxes ) {
    display: inline;
    margin: 0;

    :global(.checkbox-values) {
      display: inline;
    }

    :global(.checkbox-item) {
      display: inline;

      & > label {
        color: $slate-1200;
        margin: 0;

        :global(.indicator) {
          top: -2px;
          left: -40px;
        }

        :global(.label) {
          margin-left: 0;
          margin-right: 4px;
        }
      }

      &:not(:global(.selected)) {
        :global(.indicator) {
          background-color: $white;
        }
      }
    }
  }

  :global(.tooltip__content) {
    text-decoration: underline;
    cursor: pointer;
  }
}
