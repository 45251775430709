@import '../../../../../../styles/basics';
@value payment-method-warning from '../../../../../01_atoms/PaymentModeWarning/index.module.scss';
@value button as componentButton from '../../../../../01_atoms/Button/index.module.scss';

.payments {
  margin: 0 auto;
  padding: 32px 20px;

  .payments__error {
    margin: 0 -20px 32px;
  }
  &__title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  &__description {
    font-size: 16px;
    margin-bottom: 48px;

    &-amount {
      display: block;
      font-size: 36px;
      line-height: $line-height-normal;
      margin-top: 8px;
    }
  }

  &__separator {
    display: flex;
    justify-content: center;
    padding: 44px 0;
    position: relative;
    &_line {
      width: 100%;
      height: 0;
      border-top: 1px solid $slate-100;
    }
    &_content {
      background: $white;
      font-size: 14px;
      color: $slate-800;
      position: absolute;
      padding: 34px 16px;
      top: 0;
    }
  }

  &__buttons-container {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  &__button-with-icon {
    // Override Button component.
    &.componentButton {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      margin-bottom: 8px;
      margin-right: 0;
      width: 100%;
      max-width: 600px;
      &:hover, &:focus {
        opacity: 0.7;
      }
      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        height: 32px;
        margin-top: 4px;
        width: auto;
      }

      &.payments__button-with-icon--default {
        svg {
          width: 22px;
          height: 22px;
          margin-right: 10px;
          margin-top: 0;
        }
        &:hover, &:focus {
          background: $turquoise-1200;
          border-color: $turquoise-1200;
          opacity: 1;
        }
      }

      &.payments__button-with-icon--apple-pay {
        background-color: $gray-1200;
        border-color: $gray-1200;

        &:hover, &:focus {
          background-color: $gray-1200;
          border-color: $gray-1200;
        }
      }

      &.payments__button-with-icon--google-pay {
        background-color: $gray-1200;
        border-color: $gray-1200;

        &:hover, &:focus {
          background-color: $gray-1200;
          border-color: $gray-1200;
        }
      }

      &.payments__button-with-icon--paypal {
        background-color: $color-paypal;
        border-color: $color-paypal;

        &:hover, &:focus {
          background-color: $color-paypal;
          border-color: $color-paypal;
        }
      }

      &.payments__button-with-icon--revolut {
        background-color: $gray-1200;
        border-color: $gray-1200;

        &:hover, &:focus {
          background-color: $gray-1200;
          border-color: $gray-1200;
        }
      }
    }
  }

  [data-component="button-activism-black"] {
    &:hover, &:focus {
      opacity: 0.7;
      background: $gray-1200;
      border-color: $gray-1200;
    }
  }

  .payment-method-warning {
    display: inline-block;
    font-size: 14px;
  }

  :global(.paypal-button-wrapper) {
    width: 100%;
  }

  @include media-breakpoint-up(sm) {
    max-width: 540px;
  }

  @include media-breakpoint-up(md) {
    max-width: 740px;
  }

  @include media-breakpoint-up(lg) {
    .payments__error {
      margin: 0 0 32px 0;
    }
  }

  @include media-breakpoint-up(xl) {
    max-width: 1420px;
    padding: 32px 110px;

    .payments {
      &__error {
        max-width: 600px;
      }

      &__separator {
        padding: 34px 0 32px;
        &_content {
          padding: 24px 16px;
          left: 275px;
        }
      }

      &__buttons-separator-container {
        display: flex;
        flex-direction: column;
        max-width: 600px;
      }

      &__button-with-icon {
        margin-bottom: 0;
      }
    }
  }
}
