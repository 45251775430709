.sort-code {
  margin-bottom: 32px;

  &__inline {
    display: flex;
    gap: 4px;

    [data-component="field-template"] {
      //max-width: 100px;
      margin-bottom: 0;
    }
  }
  &__inline-separator {
    height: 46px;
    display: flex;
    align-items: center;
  }
}

.small-description {
  font-size: 14px;
}

.account-number {
  :global {
    .field-payment_options\.account_number {
      margin-bottom: 16px;
    }
  }
}