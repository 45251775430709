@import '../../../../../styles/basics.scss';

.activism-resource {
  background: $white;
  border: 1px solid rgba(#000, .05);
  border-radius: 9px;
  color: $gray-1200;

  &__content {
    display: flex;
    flex-direction: column;
    max-width: 1056px;
    margin-left: auto;
    margin-right: auto;
  }

  &__image-group {
    padding: 48px 40px 0;
  }

  &__info-group {
    padding: 36px 16px 48px;
  }

  &__image {
    aspect-ratio: 314/290;
    position: relative;
    max-width: 237px;
    // Smooth rotating in Chrome.
    -webkit-backface-visibility: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }

    .step--type-1 & {
      transform: rotate(9.36deg);
    }

    .step--type-2 & {
      transform: rotate(-8.7deg);
    }

    .step--type-3 & {
      transform: rotate(-6.91deg);
    }

    .step--type-4 & {
      transform: rotate(-1.77deg);
    }
  }

  &__info {
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
  }

  &__number {
    font-size: 32px;
    font-weight: $font-weight-bold;
    width: 64px;
    height: 63px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    color: $white;
  }

  &__number-bg {
    width: 100%;
    height: auto;
    position: absolute;
    fill: $blue-600;
    z-index: -1;
  }

  &__title {
    font-size: 28px;
    margin-top: 16px;
    color: $gray-1200;
  }

  &__description {
    font-size: 18px;
    margin-top: 8px;
    * {
      font-size: 18px;
    }
  }

  &__resources-container {
    margin-top: 24px;
  }

  @include media-breakpoint-up(md) {
    &__image {
      margin-left: 40px;
    }
  }

  @include media-breakpoint-up(lg) {
    &__content {
      flex-direction: row-reverse;
    }

    &__image-group {
      width: 40%;
      padding: 120px 56px;
    }

    &__image {
      max-width: 314px;
      margin-left: 0;
    }

    &__info-group {
      width: 60%;
      padding: 80px 24px;
    }

    &__info {
      margin-left: 0;
    }

    &__number {
      font-size: 36px;
      width: 81px;
      height: 80px;
    }

    &__title {
      font-size: 36px;
      margin-top: 24px;
    }

    &__description {
      font-size: 22px;
      margin-top: 16px;
      * {
        font-size: 22px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .step--type-1 & {
      right: 20px;
    }

    .step--type-2 & {
      left: -34px;
    }

    .step--type-3 & {
      right: -2px;
    }

    .step--type-4 & {
      left: 10px;
    }
  }

}
