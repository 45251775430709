@import '../../../styles/gifts/sass/basics';

.bb-contact-us {
  //max-width: 1440px;
  margin: 36px auto;
  background-color: $white;

  &__container {
    display: flex;
    flex-direction: column;
  }
  &__text {
    padding-bottom: 36px;
  }

  [data-component="button-primary"] {
    display: inline-block;
    background-color: $green-600;
    border: 2px solid transparent;
    width: 100%;
    max-width: 600px;
    transition: all 0.3s ease-out;
    font-size: 14px;
    font-weight: 400;
    height: 48px;
    padding: 0 22px;
    vertical-align: middle;
    white-space: nowrap;
    border-radius: 5px;
    color: $btn-font-color-alt;

    &:hover,
    &:active {
      cursor: pointer;
      color: $btn-font-color-alt;
      border-color: transparent;
      background-color: $green-800;
    }
    &:focus,
    &:focus-visible {
      border-color: transparent;
      background-color: $green-800;
    }
  }

  span[data-component="field-error"] {
    display: block;
  }

  @include media-breakpoint-up(md) {
    margin: 48px auto;
    &__container {
      flex-direction: row;
      justify-content: space-between;
    }

    &__text {
      flex: 45% 1 0;
      padding-bottom: 48px;
    }
    &__form {
      flex: 55% 1 0;
    }
  }
}