@import '../../../../styles/basics';

.bb-activism-webform {
  @include bb-activism-spacing();
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;

  &__content-wrapper {
    background: $white;
    border: 1px solid rgba(#000, .05);
    border-radius: 9px;
    color: $gray-1200;
    padding: 16px;
  }

  a {
    color: $pink-800;
  }

  @include media-breakpoint-up(xl) {
    &__content-wrapper {
      padding: 64px 80px;
    }
  }
}
