@import '../../../styles/basics.scss';

.resource-download {
  border: 1px solid $slate-200;
  border-radius: 8px;
  padding: 15px 20px 15px 15px;
  display: block;
  text-decoration: none;
  margin: 10px 0;

  &:focus-visible,
  &:hover {
    transform: scale(1.01);
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
    text-decoration: none;

    .resource-download__title,
    .resource-download__info {
      text-decoration: underline;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &__details {
    flex: 1;
    color: $turquoise-1200;
    margin-right: 40px;
  }

  &__title {
    font-weight: $font-weight-bold;
    display: inline;
    padding-bottom: 1px;
  }
  &__info {
    display: inline;
    padding-bottom: 1px;
  }
  &__description {
    color: $black;
    font-size: 16px;
    margin-top: 6px;
  }

  &__icon-filetype {
    display: none;
  }

  &__action-icon-tab,
  &__action-icon-download {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 2px;
    right: 0;
  }

  &__action-icon-tab {
    width: 16px;
    height: 16px;
    top: 6px;
    margin-right: 2px;
  }

  @include media-breakpoint-up(md) {
    padding: 16px 26px;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &__details {
      font-size: 18px;
      margin-right: auto;
    }

    &__icon-filetype {
      width: 36px;
      height: 36px;
      margin-right: 15px;
      display: block;
    }

    &__action-icon-tab,
    &__action-icon-download {
      position: initial;
      margin-left: 36px;
      margin-right: 20px;
    }
    &__action-icon-tab {
      margin-right: 22px;
    }
  }
}

.resource-download--activism.resource-download {
  border-color: $pink-800;
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;

  .resource-download {
    &__info,
    &__title {
      color: $pink-800;
      font-size: 16px;
    }
    &__description {
      color: $gray-1200;
      margin-top: 0;
      hyphens: auto;
      hyphenate-character: '-';
      hyphenate-limit-chars: 15 4 4;
    }

    &__action-icon-tab,
    &__action-icon-download {
      path {
        fill: $pink-800;
      }
    }
    &__icon-filetype {
      width: 48px;
      height: 48px;
      path {
        fill: $pink-800;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .resource-download {
      &__title {
        font-size: 22px;
      }
      &__description {
        font-size: 18px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    max-width: none;
  }
}
