@import '../../../../../../styles/basics';

.bank-account-form {
  background: $slate-200;
  margin: 0 auto;

  &__container {
    margin: 0 auto;
    padding: 32px 20px;
  }

  &__title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  &__description {
    font-size: 16px;
    margin-bottom: 32px;
  }

  &__submit-button {
    margin-top: 16px;
  }

  &__submit-button {
    margin-top: 32px;
  }

  :global {
    .field-type-radios > label {
      margin-bottom: 0;
    }
    .radio-buttons-inline {
      [data-component="field-type-radios-button"],
      [data-component="field-type-radios-button--selected"] {
        display: inline-block;
        margin-right: 24px;

        &.selected .label {
          text-shadow: 0 0 0.5px $turquoise-1000;
        }
      }
    }
    .text-with-popup {
      font-size: 14px;
    }
  }

  @include media-breakpoint-up(sm) {
    .bank-account-form {
      &__container {
        max-width: 540px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .bank-account-form {
      &__container {
        max-width: 740px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    background: none;
    max-width: 1420px;
    padding: 0 70px;
    .bank-account-form {
      &__container {
        max-width: none;
        background: $slate-200;
        padding: 32px 40px 24px;
      }

      &__container-left {
        max-width: 600px;
      }
    }
  }
}
