@import '../../../../styles/basics';

.bb-multiple-steps {
  &:global(.bb) {
    @include bb-standalone-spacing();
  }

  &:global(.behaviour-settings__top-spacing--no_spacing) {
    margin-top: 0;
  }

  &:global(.behaviour-settings__bottom-spacing--no_spacing) {
    margin-bottom: 0;
  }

  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;

  &__header {
    margin-bottom: 24px;
  }

  &.bb-multiple-steps--variant-activism {
    @include bb-activism-spacing();

    &:global(.behaviour-settings__top-spacing--no_spacing) {
      margin-top: 8px;
    }

    &:global(.behaviour-settings__bottom-spacing--no_spacing) {
      margin-bottom: 8px;
    }

    .bb-multiple-steps__title {
      font-size: 28px;
    }

    .bb-multiple-steps__items-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  @include media-breakpoint-up(lg) {
    &.bb-multiple-steps--variant-activism {
      .bb-multiple-steps__header {
        margin-bottom: 32px;
      }

      .bb-multiple-steps__title {
        font-size: 36px;
      }
    }
  }
}
